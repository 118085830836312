import * as React from 'react';

import DataGrid, { Column, Export, Selection, SearchPanel, Sorting, GroupPanel, Grouping, FilterRow, HeaderFilter, FilterPanel, ColumnFixing, Pager, Paging, ColumnChooser, MasterDetail } from 'devextreme-react/data-grid';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import {
    NavLink
} from "react-router-dom";
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import ContextMenu from 'devextreme/ui/context_menu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewListIcon from '@material-ui/icons/ViewList';
import DetailTemplate from './Detailtemplate';
import { Switch } from 'devextreme-react';

const allowedPageSizes = [10, 20, 40, 80, 'all'];
export default class DataTable extends React.Component<any, any> {
    public dataGrid: any;
    public applyFilterTypes: any;
    constructor(props: any) {
        super(props);
        this.dataGrid = null;
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        let filterValueStorage = localStorage.getItem("datagridFilterValue");
        let searchPanelValueStorage = localStorage.getItem("datagridSearchPanelValue");
        let filterValue: any = [];
        let searchValue: string = "";
        if (filterValueStorage) {
            let tempFilterValue = JSON.parse(filterValueStorage)

            if (tempFilterValue.listName === this.props.fileName) {
                filterValue = JSON.parse(tempFilterValue.value);
            } else {
                filterValue = [];
                localStorage.removeItem('datagridFilterValue');
            }
        }
        if (searchPanelValueStorage) {
            let tempSearchPanelValueStorageValue = JSON.parse(searchPanelValueStorage)
            if (tempSearchPanelValueStorageValue.listName === this.props.fileName) {
                searchValue = tempSearchPanelValueStorageValue.searchValue;
            } else {
                searchValue = '';
                localStorage.removeItem('datagridSearchPanelValue');
            }
        }
        this.state = {
            data: this.props.rows,
            columns: this.props.columns,
            showCommandBtn: this.props.showCommandBtn,
            showViewDetailBtn: !this.props.showViewDetailBtn,
            showEditPlantBtn: !this.props.showEditPlantBtn,
            currentFilter: 'auto',
            displayMode: 'full',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            showFilterRow: true,
            showHeaderFilter: true,
            showNewBtn: false,
            isOpen: false,
            mode: 'widget',
            // gridWidth: (window.innerWidth - 88) + 'px',
            filterValue: filterValue,
            // filterValue: searchValue?[]:filterValue,
            searchValue: searchValue,
            loadMasterDetail: true
        };
    }
    public afterInit() {
        // var drawerWidth = window.document.getElementsByClassName('MuiDrawer-paper')[0].clientWidth;

        // let gridWidth = (window.innerWidth - (drawerWidth + 15)) + 'px';

        this.setState({
            data: this.props.rows,
            columns: this.props.columns,
            showNewBtn: true,
            showCommandBtn: this.props.showCommandBtn,
            // gridWidth: gridWidth
        });
        this.props.stopCommsAfterInit();

    }
    public Cell = (props: any, item: any) => {
        let URL = window.location.href;
        let parentURL = URL.split("#");
        if (item.link === 'workorderLink') {
            let link: any;
            if (props.data.fieldstatus === 'Draft') {
                link = "/CreateWorkorder?WONum=" + props.data.woid + "_" + props.data.Workordernumber;
            } else {

                if (props.data.relatedWO && props.data.relatedWO === "Yes") {
                    link = parentURL[0] + "/#/WorkOrderDetail?WOID=" + props.data.woid + "&WONum=" + props.data.Workordernumber;
                }
                else {
                    link = "/WorkOrderDetail?WOID=" + props.data.woid + "&WONum=" + props.data.Workordernumber;
                }
            }
            if (props.data.relatedWO && props.data.relatedWO === "Yes") {
                return (
                    <div>
                        <a href={link} target="" className={"WOlink"}>{props.text}</a>
                    </div>
                );
            }
            else {
                return (
                    <div>
                        <NavLink
                            to={link}
                            className={"WOlink"}

                        >
                            {props.text}
                        </NavLink>
                    </div>
                );
            }

        } else if (item.link === 'scheduleLink') {
            let link = "/EditScheduleWork?ID=" + props.data.id;
            return (
                <div>
                    <NavLink
                        to={link}
                        className={"WOlink"}

                    >
                        {props.text}
                    </NavLink>
                </div>
            );
        }
        else if (item.link === 'assetLink') {
            let link = "/AssetDetail?ASSETNO=" + props.data.AssetNumber + "&compkey=" + props.data.Compkey + "&type=" + props.data.AssetTypeId;
            if (this.props.selectMode || this.props.selectScheduleMode || this.props.selectCreateScheduleMode) {
                return (
                    <div className={"WOlink"} onClick={() => this.props.selectItem(props.data)}>{props.text}</div>
                );
            } else {
                return (
                    <div>
                        <NavLink
                            to={link}
                            className={"WOlink"}
                        >
                            {props.text}
                        </NavLink>
                    </div>
                );
            }
        } else if (item.link === 'file') {
            if (props.data.fileURL.toLowerCase()?.includes('<a href=')) {
                return (
                    <div className={'dataGridText WOlink'} dangerouslySetInnerHTML={{ __html: props.data.fileURL.toLowerCase() }}>
                        {props.data.fileExt && (props.data.fileExt === "jpg" || props.data.fileExt === "png" || props.data.fileExt === "jpeg") &&
                            <img src={props.data.fileURL} onClick={() => this.props.handleOpen(props.data)}></img>
                        }
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className={'dataGridText WOlink'} onClick={() => this.props.viewFile(props.data)}>
                            {props.text}
                        </div>
                        {props.data.fileExt && (props.data.fileExt === "jpg" || props.data.fileExt === "png" || props.data.fileExt === "jpeg") &&
                            <img src={props.data.fileURL} style={{ height: "190px" }} onClick={() => this.props.handleOpen(props.data)}></img>
                        }

                    </div>
                );
            }
        }
        else if (item.link === 'toggle') {
            return (
                <div className={'alignCenter'}>
                    <Switch
                        onValueChange={(e: any) => this.props._changeInspectiponFailed(e, props.data)}
                        value={props.data.inspectionFailed}
                    />
                </div>
            )
        }
        else if (item.isArray) {
            let tempArray: any = [];
            if (Array.isArray(props.value) && props.value) {
                props.value.forEach((r: any) => {
                    tempArray.push(r.Title);
                })
            }
            return (
                <div className={'dataGridText'} style={{ color: props.text ? props.text : '#333' }}>
                    {Array.isArray(props.value) && props.value.map((item: any) => {
                        return (
                            <span>{tempArray.join()}</span>
                        )
                    })}
                </div>
            );
        }
        else if (item.link === 'cellrender') {
            <div className={'dataGridText'} dangerouslySetInnerHTML={{ __html: `${props.data.Comments.replace(/\n/g, "<br />")}` }}></div>
        }
        else {

            return (
                <div className={'dataGridText'} style={{ color: props.text ? props.text : '#333' }}>
                    {props.text}
                </div>
            );
        }
    }

    public CommandCell = (props: any) => {
        return (
            <div >
                {!this.props.hideCommandDeleteBtn && (
                    <DeleteIcon className={'danger dataGridIcon'} onClick={() => this.props.deleteItem(props.data.id ? props.data.id : props.data)} />
                )}
                {!this.props.hideCommandEditBtn && (
                    <EditIcon className={'primary dataGridIcon'} onClick={() => this.onClickEdit(props.data)} />
                )}
                {this.props.showViewDetailBtn && (
                    <ViewListIcon className={'primary dataGridIcon'} onClick={() => this.props.viewItem(props.data)} />
                )}
            </div>
        );
    }
    public onClickEdit(data: any) {
        this.props.editItem(data);
    }
    public viewDetail = (props: any) => {
        return (
            <ViewListIcon className={'primary dataGridIcon'} onClick={() => this.props.viewItem(props.data)} />
        );
    }

    // This function id for edit plant usage
    public EditItem = (props: any) => {
        return (
            <div>
                <EditIcon className={'primary'} onClick={() => this.props.editItem(props.data)} />
            </div>
        );
    }
    public rowClick = (event: any) => {

        // var drawerWidth = window.document.getElementsByClassName('MuiDrawer-paper')[0].clientWidth;

        // let gridWidth = (window.innerWidth - (drawerWidth + 15)) + 'px'
        if (this.props.showCommandBtn) {
            this.setState({
                showCommandBtn: !this.state.showCommandBtn,
                // gridWidth: gridWidth
            })
        }
        if (this.props.showViewDetailBtn) {
            this.setState({
                showViewDetailBtn: !this.state.showViewDetailBtn,
                // gridWidth: gridWidth
            })
        }
        if (this.props.showEditPlantBtn) {
            this.setState({
                showEditPlantBtn: !this.state.showEditPlantBtn,
                // gridWidth: gridWidth
            })
        }
    }



    public render(): React.ReactElement<any> {
        if (this.props.callAfterInit) {
            this.afterInit();
        }
        let isNarrow = false;
        if (window.innerWidth < 730) {
            isNarrow = true;
        }

        return (

            <div className={'dataTableDiv'}>

                {this.state.data && (
                    <>
                        <DataGrid
                            //  ref={this.dataGridRef}
                            ref={(ref) => this.dataGrid = ref}
                            // style={{ width: this.state.gridWidth }}
                            id="gridContainer"
                            dataSource={this.state.data}
                            keyExpr={this.props.id ? this.props.id : ""}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            columnResizingMode={this.state.mode}
                            columnMinWidth={10}
                            columnAutoWidth={true}
                            onContentReady={this.onContentReady}
                            onRowDblClick={this.rowClick}
                            onSelectionChanged={this.onSelectionChanged}
                            onExporting={(e: any) => this.onExporting(e, this.props.fileName)}
                            columnHidingEnabled={true}
                            defaultFilterValue={this.state.filterValue}
                            // onFilterValueChange={(e:any)=>this.onFilterValueChanged(e)}
                            remoteOperations={true}
                            hoverStateEnabled={true}
                        >

                            <Sorting mode="multiple" />
                            {/* <Scrolling rowRenderingMode='virtual'></Scrolling> */}
                            {this.props.showHideColumnMenu && <ColumnChooser enabled={true} mode="select" allowSearch={true} height={400} />}
                            <ColumnFixing enabled={true} />
                            {this.props.selectionMode && <Selection mode={this.props.selectionMode} />}
                            {!this.props.hideSearchPanel && <SearchPanel visible={true} width={'100%'} defaultText={this.state.searchValue} onTextChange={(e) => this.onSearchValueChange(e)} />}
                            {this.state.columns && this.renderColums()}
                            {(this.state.showCommandBtn && this.props.showCommandBtn) && (
                                <Column
                                    caption='Action'
                                    width={100}
                                    cellRender={this.CommandCell}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowHiding={false}
                                    allowExporting={false}
                                />
                            )}
                            {(this.state.showViewDetailBtn && this.props.showViewDetailBtn) && (
                                <Column
                                    caption='View'
                                    width={70}
                                    cellRender={this.viewDetail}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowHiding={false}
                                    allowExporting={false}
                                />
                            )}
                            {(this.state.showEditPlantBtn && this.props.showEditPlantBtn) && (
                                <Column
                                    caption='Edit'
                                    width={100}
                                    cellRender={this.EditItem}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowHiding={false}
                                    allowExporting={false}
                                />
                            )}
                            <Paging defaultPageSize={10} />
                            <Pager
                                visible={true}
                                allowedPageSizes={allowedPageSizes}
                                displayMode={this.state.displayMode}
                                showPageSizeSelector={this.state.showPageSizeSelector}
                                showInfo={this.state.showInfo}
                                showNavigationButtons={this.state.showNavButtons} />
                            {this.props.filteralbe && <FilterRow visible={this.state.showFilterRow}
                                applyFilter={this.state.currentFilter} />}

                            {this.props.filteralbe && <FilterPanel visible={true} />}
                            {this.props.filteralbe && <HeaderFilter visible={this.state.showHeaderFilter} />}

                            {this.props.groupable && <GroupPanel visible={isNarrow ? false : true} />}
                            {this.props.groupable && <Grouping autoExpandAll={true} />}
                            {!this.props.hideExport && <Export enabled={true} allowExportSelectedData={this.props.allowExportSelectedData} />}
                            {this.props.showDetailTable && (
                                <MasterDetail
                                    autoExpandAll={false}
                                    enabled={this.props.showNewBtn && !this.props.hideNewBtn}
                                    component={(e) => this.renderDetailTemplate(e, this.state.data, this.props.detailColumns)}
                                />
                            )}
                        </DataGrid>
                        {(this.props.showNewBtn && !this.props.hideNewBtn) &&
                            <SpeedDialAction
                                icon="add"
                                label="New"
                                // visible={false}
                                onClick={() => this.props.showNewModal(true)} />
                        }
                    </>
                )}

            </div>
        );
    }
    public renderDetailTemplate(e: any, data?: any, detailColumns?: any) {
        return (
            <DetailTemplate {...e} columns={detailColumns} dataSource={data} />
        )
    }
    public renderColums() {
        return (
            this.state.columns && this.state.columns.map((item: any) => {
                return (
                    <Column
                        dataField={item.field}
                        width={item.width}
                        caption={item.title}
                        dataType={item.type}
                        visible={item.visibleColumn}
                        visibleIndex={item.visibleIndex}
                        allowHiding={item.allowHiding}
                        encodeHtml={false}
                        format={item.type === 'date' ? "dd/MM/yyyy" : item.type === 'datetime' ? 'dd/MM/yyyy h:mm a' : ''}
                        // cellRender={this.WOLinkCell}
                        cellRender={(e: any) => this.Cell(e, item)}
                        sortOrder={item.sortOrder}
                    // editorOptions={{showClearButton:true}}
                    >
                    </Column>
                )
            })
        );
    }
    public onExporting(e: any, name: any) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${name}.xlsx`);
            });
        });
        e.cancel = true;
    }

    public onSelectionChanged(e: any) {
        const exportSelectedDisabled = !!e.component.getSelectedRowKeys().length;
        const exportMenu = e.element.querySelector('.dx-datagrid-export-menu');
        if (exportMenu) {
            const instance = ContextMenu.getInstance(exportMenu) as ContextMenu;
            instance.option('items[1].disabled', exportSelectedDisabled);
        }
    }
    // public onFilterValueChanged =(e:any)=>{      
    //     this.setState({filterValue:e})
    // }
    public onSearchValueChange = (e: any) => {

        let tempSearchPanelValue: any = {
            listName: this.props.fileName,
            searchValue: e
        }
        localStorage.setItem("datagridSearchPanelValue", JSON.stringify(tempSearchPanelValue));
        this.setState({ searchValue: e });

    }
    public onContentReady = (e: any) => {



        if (e.component.getCombinedFilter()) {
            let flatFilterValue = e.component.getCombinedFilter().join();

            if (flatFilterValue && flatFilterValue.includes(",or,") && !this.state.searchValue) {

                localStorage.removeItem('datagridFilterValue');
                localStorage.removeItem('datagridSearchPanelValue');
                e.component.clearFilter()
            }
        }
        // if (e.component.getCombinedFilter()) {
        let filterValue = {
            listName: this.props.fileName,
            value: e.component.getCombinedFilter() ? JSON.stringify(e.component.getCombinedFilter()) : null,

        };
        localStorage.setItem('datagridFilterValue', JSON.stringify(filterValue))
        // }
        var curentColumns = e.component.getVisibleColumns();
        let columnlist = this.state.columnlist;
        let tempColumns = this.props.columns;

        if (columnlist) {
            var modifiedColumn: any;

            if (curentColumns.length > columnlist.length) {
                //Added Column
                modifiedColumn = this.checkColumnChanged(curentColumns, columnlist);
                const index = tempColumns.findIndex((c: any) => c.field === modifiedColumn);
                if (index >= 0) {
                    //visibleIndex
                    tempColumns[index].visible = true;
                    localStorage.setItem(this.props.columnChooser, JSON.stringify(tempColumns));
                }
            }
            else {
                //remove columns
                modifiedColumn = this.checkColumnChanged(columnlist, curentColumns);

                const index = tempColumns.findIndex((c: any) => c.field === modifiedColumn);
                if (index >= 0) {
                    tempColumns[index].visible = false;
                    localStorage.setItem(this.props.columnChooser, JSON.stringify(tempColumns));
                }
            }
            columnlist = e.component.getVisibleColumns();
            this.setState({ columnlist: columnlist });
        }
        else {
            columnlist = e.component.getVisibleColumns();
            this.setState({ columnlist: columnlist });
        }
        const exportSelectedDisabled = !!e.component.getSelectedRowKeys().length;
        const exportMenu = e.element.querySelector('.dx-datagrid-export-menu');
        if (exportMenu) {
            const instance = ContextMenu.getInstance(exportMenu) as ContextMenu;
            instance.option('items[1].disabled', exportSelectedDisabled);
        }
    }


    // this code is for show and hide columns
    public checkColumnChanged(colList1: any, colList2: any) {
        for (var i = 0; i < colList1.length; i++) {
            var col = colList1[i].dataField;
            if (col) {
                var foundcol = false;
                for (var a = 0; a < colList2.length; a++) {
                    var colOld = colList2[a].dataField;
                    if (col = colOld) {
                        foundcol = true;
                    }
                }
                if (!foundcol) {
                    return col;
                }
            }
        }
        return "";
    }
}


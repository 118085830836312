import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class ViewWOQuestionDetails extends React.Component<any, any> {
    constructor(props: any) {
        super(props);


        this.state = {
            builderLink: '/',
            isActive: true,
        };
    }
    public didMount() {
        console.log(this.props.details.inspectionFields);
    }


    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.props.onClose()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.details ? this.props.details.formTypeTitle : ''}`}
                        <IconButton aria-label="close" onClick={() => this.props.onClose()} className="closeIcon" style={{ float: "right" }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers={true}>

                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                {(this.props.details && this.props.details.inspectionFields) && this.props.details.inspectionFields.length > 0 && (
                                    <div>
                                        {this.props.details.inspectionFields.map((item: any, i: any) => {
                                            return (<div>
                                                <Grid container>
                                                    <Grid item xs={12} sm={4}>
                                                        <p className='p1'>{item.caption}</p>
                                                    </Grid>
                                                    {(item.fieldType != 'Photo-View Only') && (
                                                        <Grid item xs={12} sm={8}>
                                                            <p className='p2'>{item.value}</p>
                                                        </Grid>
                                                    )}
                                                    {(item.fieldType == 'Photo-View Only') && (
                                                        <Grid item xs={12} sm={8}>
                                                            {item.value.map((docItem: any, i: any) => {
                                                                return (<a href={docItem.url} target="_blank"><p className='p2 link'>{docItem.name}</p></a>);
                                                            })}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </div>);
                                        })}

                                    </div>
                                )}
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.props.onClose()} variant='contained' color="primary" className={''}>
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

}


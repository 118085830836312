import global from "./global";

export const Config: any = {
    productTitle: "worxOnline",
    tenantId: 1,
    ListConfiguration: 'ListConfiguration',
    WOlistname: "WorkOrders",
    WOHistorylistname: "Work Order History",
    WODocumentslistname: "Work Order Documents",
    WOPlantslistname: "workorderplants",

    // for get api
    workorderlistname: "Workorders",
    scheduledworklistname: "Scheduledworks",
    suburblistname: "Suburbs",
    townlistname: "Towns",
    crewlistname: "Crews",
    employeelistname: "Users",
    WTlistname: "workordertypes",
    // statuslist: "workorderstatus",
    statuslist: "workorderstatuscurd",
    WOMaterialListName: "materials",
    plantListName: "Plants",
    assetType: "AssetTypes",
    assetClass: "Assetclasses",
    assetStatus: "AssetStatus",
    unitType: "UnitTypes",
    DepartmentList: "Departments",
    CounterList: "Counters",
    TeamList: "Teams",
    PriorityList: "Priorities",
    DefectCodeList: "Defecttypes",
    AssetQuestionList: "Assetfields",
    workorderHistoryList: "Workorderhistories",
    workorderDocumentList: "Workorderdocuments",
    workorderDocumentListGet: "Workorderdocuments/GetDocumentByWorkOrderId",
    assetDocumentList: "Assetdocuments",
    swmsDocumentList: "Swmsdocuments",
    searchswmsDocumentList: "Swmsdocuments/SearchSwms",
    tcpDocumentList: "Trafficcontrolplans",
    dowloadDocumentList: "DownloadDocument",
    FormsListName: "Forms",
    PrestartFormsListName: "Prestartforms",
    PriorityListName: "Priorities",
    workTypeFieldList: "Worktypefields",
    worktypeSubcategories: "Workordertypes/GetWoWorkordertypesSubcategories",
    defectQuestionList: "Defectfields",
    roleList: "Roles",
    userRoleList: "Userroles",
    inventoryLocationList: "InventoryLocations",
    InspectionFormList: "CommonForm",
    ServiceRequestDetail: 'ServiceRequestDetail',
    MaintenanceSchedule: 'MaintenanceSchedule',
    OnHoldReasonDetailList: "onholdreasons/details",
    OnHoldReasonList: "Onholdreasons",
    AreaList: "area",
    ReasoneToAttendList: "reasontoattend",
    ScopeList: "scope",
    FormattedAddressList: "formattedaddress",
    AddressList: "Address",
    //update api for Schedule

    SchedulerWorkUpdateList: "UpdateWorkOrderSchedule",
    WorkOrderUpdateList: "UpdateWorkOrder",

    // for searc api
    WOSearchAPI: "workorders/searchWorkOrders",

    WOdetailsSearchAPI: "searchWorkOrders",
    SearchRelatedWOAPI: "searchWorkOrders/SearchRelatedWorkOrders",

    //material
    WOMaterialSearchAPI: "SearchMaterialUsage",
    SearchMaterialApi: "Materials/SearchMaterials",
    MaterialCategoryApi: "MaterialCategories",

    //plant
    WOPlantsSearchAPI: "SearchPlantsUsage",
    SearchPlantApi: "SearchPlants",
    PlantCategoryApi: "PlantCategories",

    SearchPrestartChecklist: "Prestartchecklist/SearchPrestartChecklistsDocuments",
    SearchInspectionForm: "CommonForm/SearchCommonFormsDocuments",
    SearchEmployeeApi: "SearchEmployees",
    SearchActivitiesApi: "workordertypes/SearchActivities",
    SearchWorkTypeField: "Worktypefields/SearchWorktypeFields",
    SearchDefect: "SearchDefects",
    SearchDefectCode: "SearchDefectTypes",
    SearchDefectQuestion: "SearchDefectFields",
    SearchCrewApi: "Crews/SearchCrew",
    SearchTeamApi: "SearchTeams",
    SearchAssetFields: "SearchAssetFields",
    AssetSearchAPI: "/Assets/SearchAssets",
    AssetSearchAPIForDetail: "/SearchAssets",
    AddEditAset: "Assets/AddAssetFromApp",
    WOTimesheetSearchAPI: "SearchTimeSheets",
    WOHistorySearchAPI: "SearchJobHistory",
    WOSearchScheduleAPI: "SearchScheduledWorks",
    SearchControlMeasure: "SearchControlMeasures",
    SearchRisk: "Risks/SearchRisks",
    SearchRiskControlMeasure: "SearchControlMeasures",
    SearchPurchaseRequisition: "PurchaseRequisitions/searchPurchaseRequisitions",
    searchSuppliersList: "Suppliers/searchSuppliers",

    //risk
    RiskCategoryList: "Riskcategories",
    RiskList: "Risks",
    RiskFormsList: "Riskforms",
    RiskControlMeasureList: "Riskcontrolmeasures",
    RiskAreaList: "RiskAreas",

    //new risk
    NewRiskCategoryList: "RiskCategoriesWhs",
    NewRiskList: "RisksWhs",
    RiskHierarchy: "RiskHierarchyWhs",
    NewRiskFormsList: "RiskFormsWhs",
    NewRiskAreaList: "RiskGroupsWhs",
    GetAllRisksWithRiskGroups: "RisksWhs/GetAllRisksWithRiskGroups",
    GetAllRisksGrpWithRiskCategory: "RiskGroupsWhs/GetAllRiskGroupsWithRiskCategories",
    NewRiskControlMeasureList: "RiskControlMeasuresWhs",
    NewGetAllRisksWithRiskGroups: "Risks/GetAllRisksWithRiskGroups",
    RiskCategorieswithGroups: "RiskCategoriesWhs/GetAllRiskCategoriesWithRiskGroups",
    RiskGroupWithRisks: "RiskGroupsWhs/GetAllRiskGroupsWithRisks",
    //extra
    WTQuestionslistname: "Activity Questions",
    timesheetListName: "New Employee Timesheet",
    gisAssetCoordinates: "GisAsset/getAssetCoordinates",
    //Defects
    searchDefects: "defects/searchDefects",

    TenantApi: 'tenants',
    GloabSearchAPI: 'globalsearch?searchterm=',
    AssetdetailURL: global.homeURL + "/AssetDetail?ASSETNO=",
    seacrhWorkOrderURL: global.homeURL + "/Searchworkorder",

    IntegrationStatusapi: 'IntegrationStatus',
    Notificationapi: "Notifications/SearchByDate",
    SearchDashboardWorkordersapi: "Workorders/SearchDashboardWorkorders",
    FailedWorkordersapi: "WorkorderSyncFailed",
    WOQuestionsAPI: "/WorkorderTypeFieldsSubmitted/SearchWorkorderTypeFieldsSubmitted",

    statusColors: {
        'Open': '#90C3D4',
        'Dispatched': '#90C3D4',
        'OffsiteHold': '#FB8E54',
        'JobComplete': '#A1D490',
        'OffsiteComplete': '#A1D490',
        'WorkComplete': '#A1D490',
        'Complete': '#A1D490',
        'Accepted': '#F4A498',
        'Disapatched': '#C9C65F',
        'Travelling': '#6666FF',
        'WaterOff': '#C733FF',
        'WaterOn': '#C733FF',
        'WorkPaid': '#C733FF',
        'Cancelled': '#C733FF',
        'CID': '#C733FF',
        'CIP': '#C733FF',
        'Closed': '#C733FF',
        'Onsite': '#C733FF',
        'Queried': '#C733FF',
        'Rejected': '#C733FF',
        'Restored': '#C733FF',
        'ServiceRestored': '#C733FF',
        'SewerContained': '#C733FF'
    },
    priorityColors: {
        'Priority 1': '#ff0000',
        'Priority 2': '#e6b800',
        'Priority 3': '#00cc00',
        'Planned within 14 Days': '#0066ff',
        'Planned within 30 Days': '#cc99ff',
        'Planned within 60 Days': '#ff4d88',
        'Proposed Work': '#e60099',
        'Planned Shutdown': '#00b3b3',
        'PWeekly within 7 Days': '#9494b8',
        'Yearly (Due 30 Jun of FY)': '#999999',
    },

};
export default Config;